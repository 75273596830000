import "./main.css";

// Setting the url in iframe
window.onload = function () {
  const cookies = document.cookie;
  if (getURLParam("ref") === "tcb") {
    s_sq = readMyschCookie("s_sq");
    if (s_sq.includes("Cart-AddSubscript")) {
      isTCBTrial = true;
      console.log("TCB Trial flow enabled");
    }
  }

  if (getURLParam("register") === "true") {
    document.getElementById("ifrm").src = `${
      process.env.originURL
    }/register/my-info.html${
      getURLParam("ref") === "" ? "" : `?ref=${getURLParam("ref")}`
    }${getURLParam("chat") === "" ? "" : `&chat=${getURLParam("chat")}`}`;
  } else {
    document.getElementById("ifrm").src = `${
      process.env.originURL
    }/my-scholastic/sign-in.html${
      getURLParam("ref") === "" ? "" : `?ref=${getURLParam("ref")}`
    }${getURLParam("chat") === "" ? "" : `&chat=${getURLParam("chat")}`}`;
  }
};

// setting up for the bookfair flow
let isBookFairFlow = false;
let isChairPersonFlow = false;

// for tcb
let isTCBTrial = false;
let s_sq = "";
let tcbRedirectLink = "";

// UTILITY FUNCTIONS

// gets the params from url
function getURLParam(name) {
  const url = window.location.href;
  const query_string = url.split("?");
  if (query_string.length <= 1) {
    return "";
  }
  const params = query_string[1].split("&");
  let i = 0;
  while (i < params.length) {
    const param_item = params[i].split("=");
    if (param_item[0] == name) {
      if (param_item[0] == "ref" && param_item[1] == "") {
        return "corphome";
      }
      return param_item[1];
    }
    i++;
  }
  return "";
}

// redirects to the register url
function redirectRegister(registerURL) {
  window.location.replace(registerURL);
}

// checks if the page has iframe
function pageHasIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

// reads the cookie for the myschl cookie
function readMyschCookie(name) {
  const nameEQ = `${name}=`;
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    while (cookie.charAt(0) == " ") cookie = cookie.substring(1, cookie.length);
    if (cookie.indexOf(nameEQ) == 0) {
      let toReturn = cookie.substring(nameEQ.length, cookie.length);
      // if(getURLParam('ref') === "tcb"){
      //   for(let i = 0; i< 3;i++){
      //     toReturn = decodeURI(toReturn);
      //   }
      // }
      return toReturn;
    }
  }
  console.log("The cookie " + name + " doesnt exist.");
  return null;
}

// sets domain of the site to scholastic.com
function setDomainToScholastic() {
  if (document.domain.indexOf("scholastic") != -1) {
    document.domain = `scholastic.${document.domain.split(".").pop()}`;
  }
  if (isTCBTrial) {
    tcbRedirectLink = tcbRedirect(parent.location.href);
  }
}

// listening for broadcasts from the login app
window.addEventListener(
  "message",
  (event) => {
    afterLoginResponse(event);
  },
  true
);

function tcbRedirect(parentURL) {
  if (parentURL.includes("/teachables/lp/new-low-price.html")) {
    return (process.env.tcb +
      "/on/demandware.store/Sites-tcb-us-Site/default/Cart-AddSubscriptionToBasket?partNumber=9781338586336-tcb-us&quantity=1&campaignCode=fty50newlow"
    );
  } else if (parentURL.includes("/teachables/subscriptions-annual.html")) {
    return (process.env.tcb +
      "/on/demandware.store/Sites-tcb-us-Site/default/Cart-AddSubscriptionToBasket?partNumber=9781338578300-tcb-us&quantity=1&campaignCode=test"
    );
  } else if (parentURL.includes("/teachables/lp/golden-apple-free-trial.html")) {
    return (process.env.tcb +
      "/on/demandware.store/Sites-tcb-us-Site/default/Cart-AddSubscriptionToBasket?partNumber=9781338262841-tcb-us&quantity=1&campaignCode=ftgoldbun&isEmailLink=true"
    );
  } else if (parentURL.includes("/teachables/lp/green-apple-free-trial.html")) {
    return (process.env.tcb +
      "/on/demandware.store/Sites-tcb-us-Site/default/Cart-AddSubscriptionToBasket?partNumber=9781338233834-tcb-us&quantity=1&campaignCode=ftgreenbun&isEmailLink=true%20"
    );
  } else if (parentURL.includes("/teachables/lp/red-apple-free-trial.html")) {
    return (process.env.tcb +
      "/on/demandware.store/Sites-tcb-us-Site/default/Cart-AddSubscriptionToBasket?partNumber=9781338262858-tcb-us&quantity=1&campaignCode=ftredbun&isEmailLink=true"
    );
  } else if (parentURL.includes("/teachables/subscriptions-monthly.html")) {
    return (process.env.tcb +
      "/on/demandware.store/Sites-tcb-us-Site/default/Cart-AddSubscriptionToBasket?partNumber=9781338652727-tcb-us&quantity=1&campaignCode=monthly999"
    );
  } else {
    return (process.env.tcb);
  }
}

function refreshPage() {
  let referer = readMyschCookie("MyScholasticRefererURL");
  console.log(`referer: ${referer}`);
  if (pageHasIframe()) {
    try {
      setTimeout(() => {
        setDomainToScholastic();
        if (isTCBTrial) {
          parent.location.href = tcbRedirectLink;
          return;
        }
        if (isChairPersonFlow || referer == "bfaem") {
          setDomainToScholastic();
          parent.location.href =
            process.env.bookfairs + "/bookfairs/my-fairs/all-my-fairs.html";
        } else if (
          referer &&
          referer.toLowerCase().indexOf("bookfairs") > -1 &&
          getURLParam("ref") !== "ewallet"
        ) {
          setDomainToScholastic();
          parent.location.href =
            process.env.bookfairs + "/bookfairs/my-fairs/all-my-fairs.html";
        } else {
          parent.myScholasticOnLoginSuccess();
          parent.myScholasticOnLoginClose();
        }
      }, 1000);
    } catch (e) {
      let ocrsPath;
      const hostName = location.hostname;
      const protocol = "http://";
      ocrsPath = `${
        protocol + hostName
      }/etc/designs/scholastic-myaccounts/clientlibs/core/scripts/ocrs-html/ocrs.html`;
      location.href = ocrsPath;
    }
  } else {
    try {
      setTimeout(() => {
        if (referer !== null) {
          referer = unescape(referer);
          window.location.href = referer;
        } else {
          console.log("Ref is null");
          window.location.href = "https://www.scholastic.com/home";
        }
      }, 1000);
    } catch (e) {
      console.log(e);
      window.location.href = "https://www.scholastic.com/home";
    }
  }
}

// the main flow
// TODO test book fair flow, get rid of console logs once everything is ironed out, add adobe tracking
function afterLoginResponse(event) {
  const cookies = document.cookie;
  if (event && event.origin === process.env.originURL) {
    const dataObj = JSON.parse(event.data);
    const eventStatus = dataObj.status;
    if (eventStatus === "CLOSE") {
      refreshPage();
      console.log(`Status CLOSE: ${eventStatus}`);
    } else if (eventStatus === "SUCCESS" && dataObj !== null) {
      if (getURLParam("ref") == "bfaem") {
        setDomainToScholastic();
        parent.location.href =
          process.env.bookfairs + "/bookfairs/my-fairs/all-my-fairs.html";
      }
      if (readMyschCookie("CPTLogin") != null) {
        isChairPersonFlow = true;
        console.log("Chairperson flow enabled");
      }
      if (
        readMyschCookie("MYSCHL_EWfairID") != null ||
        getURLParam("type") == "ewallet"
      ) {
        isBookFairFlow = true;
        console.log("Bookfair flow enabled");
      }
      if (isBookFairFlow) {
        console.log("In book fair flow");
        let sUCN = getURLParam("sUCN");
        let fairId = getURLParam("fairID");
        let params = "sUCN=" + sUCN + "&" + "fairID=" + fairId;
        setDomainToScholastic();
        const newTabURL =
          process.env.myaccounts +
          "/my-scholastic/profile/create-ewallet.html?" +
          params; // + newParams;
        if (getURLParam("ref") === "ewallet") {
          parent.location.href = newTabURL;
        } else {
          let request = new XMLHttpRequest();
          request.open("GET", process.env.myaccounts + "/api/ewalletsv", false);
          request.setRequestHeader("Content-Type", "application/json");
          request.onload = function (fairData) {
            for (i = 0; i < fairData.length; i++) {
              if (fairData[i].fairId == fairId) {
                setDomainToScholastic();
                newTabURL =
                  process.env.myaccounts +
                  "/my-scholastic/profile/ewallet.html";
              }
            }
            parent.location.href = newTabURL;
          };
          request.send();
        }
      }
      refreshPage();
    } else if (eventStatus === "RESET") {
      console.log(`Status RESET: ${eventStatus}`);
    } else if (eventStatus === "REDIRECT") {
      console.log(`Status REDIRECT: ${eventStatus}`);
      redirectRegister(Object.values(dataObj.data));
    }
  }
}
